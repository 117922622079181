@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can keep custom styles here if needed, but try to use Tailwind classes when possible */
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
  background: #f4f4f4;
}

::-webkit-scrollbar {
  width: 0.4em;
}
::-webkit-scrollbar:horizontal {
  height: 0.4em;
}
::-webkit-scrollbar-corner {
  background-color: transparent;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 100px;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:focus {
  outline: none;
  border: 1px solid #fff; /* You can change the border color or remove this line if no border is needed */
}

/* Remove or comment out the rest of the custom CSS rules */
